.error-photo{
    max-height: 250px;
    /* height: 45vh;
    width: auto; */
     width: 40vh;
    height: auto; 
    padding-top: 36px;;
}

.error-message{
    padding: 16px;
}

.error-a{
    color: #6b5e1c;
}

.error-background{
    background-color: rgb(254, 245, 221);
}

.error-a:active,
.error-a:hover{    
    color: #b3b300;
}

.visible-screen{
    height: calc(100vh - 165px);
    min-height: 480px;
}
.error-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
}

/* body{
    background-color: rgb(254, 245, 221);
} */
