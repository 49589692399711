.top-bar {
  background: #000;
  height: 40px;
  color:white;
}
.header-top-left {
  padding: 7px 0;
}

.header-top-right{
  text-align: right;
}

.top-bar .list-inline li {
  display: inline-block;
  color: #fff;
  margin-right: 15px;
  font-size: 14px;
}

.text-center {
  text-align: center;
}

.logo {
  text-transform: uppercase;
}
.logo-lg{
  font-size: 24px;
  font-weight: 600;
}
.logo-sm {
  font-size: 16px; 
  font-weight: 500;
}
.logo-xs{
  font-size: 12px;
  font-weight: 400;
}

.section-padding {
  padding-top: 60px;
  padding-bottom: 60px;
}
.service-photo {
  text-align: center;
}
.service-photo img{
  width: 80px;
  height: auto;
}

.section-title {
  text-transform: uppercase;
  font-size: 24px;
  color: #333;
  line-height: 52px;
  font-weight: 700;
  padding-bottom: 0px;
  margin-bottom: 0px;
  text-align: center;
  position: relative;
  /* text-decoration: underline;
  border-bottom:5px solid red;  */
}
.section-underline {
  margin-top: 0px;
  margin-bottom: 15px;
  padding-bottom: 0px;
  border-bottom:5px solid #6b5e1c; 
  width: 250px;
  display: inline-block;
  justify-content: center;
  margin-bottom: 30px;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
.padding-top{
  padding-top: 56px;
}

.service{
  height: 225px;
  padding: 16px 30px 40px 40px;
  background: rgb(254, 245, 221);
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgb(175 175 175 / 23%);
  position: relative;
}

.service-title{
  font-size: 18px;
  font-weight: 600;
}

.hidden{
  display: none;
}

.gallery{
  max-height: 290px;
  padding: 16px 16px 16px 16px;
  background: rgb(254, 245, 221);
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgb(175 175 175 / 23%);
  position: relative;
}

.gallery-photo {
  text-align: center;

}
.gallery-photo img{
  max-height: 240px;
  width: auto;
  /* width: auto; */
  /* height: 60%; */

}

.faq {
  padding: 20px;
  /* margin: 15px; */
  /* border-bottom: 1px solid darkgrey; */
}
.faq-question{
  font-weight: 600;
  font-size: 18px;
}
.contact-info{
  padding: 20px;
  font-size: 18px;  
}
.contact-input{
  margin: 16px;
  padding: 8px;
  width: 80%;
}
.contact-form{
  margin-top: 8px;
  /* border: 1px solid darkgrey; */
  /* box-shadow: 0 0 10px rgb(175 175 175 / 23%); */
  /* border-radius: 2; */
  padding: 16px;
}

.fab-more {
  bottom: 30px;
  right: 35px;
}
.fab-call {
  bottom: 90px;
  right: 35px;
}
.fab-email {
  bottom: 30px;
  right: 95px;
}
.fab a{
  color: white;
}
.fab {
  z-index: 1000000 !important;
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: #002800;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  line-height: 50px;
  text-align: center;
}
.fab:active,
.fab:hover{
  color: rgb(254, 245, 221);
}

.photo-modal{
  width: 100%;
}

.contact-number{
  padding:8px;
  font-weight: 700;
  font-size: 18px;
}
.footer {
  color: #a6a6a6;
  background-color: black;
  padding: 18px;
}

.lang {
  cursor: pointer;
}
.lang-active{
  border-style:solid;
  border-width: 1px;
  background-color: rgb(254, 245, 221);
  border-color: #a6a6a6;
}

.greyscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.highlight-text {
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
       1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
}
.highlight-text-dark {
  text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
       1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
}

/* .header-text{
  font-size: 1.4rem;
} */

.contact-ref{
  color: white;
}

.contact-ref:active,
.contact-ref:hover{
  color: rgb(254, 245, 221);
  text-decoration:none;
}
.para-normal-text{
  font-size: 1rem;
}
.para-small-text{
  font-size: 0.6rem;
}

.header-xsmall-text {
  font-size: 0.9rem;
}

.header-small-text {
  font-size: 1.1rem;
}

.header-medium-text {
  font-size: 1.4rem;
}

.header-large-text {
  font-size: 1.7rem;
}

.modal-background{
  background-color:  rgb(254, 245, 221);
}

.flex-wrapper{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.scroll-offset-fix{
  scroll-margin-top: 234px;
}

.mobile-photo{
  position: relative;
  display: inline-block;
}

.mobile-btn-call{
  position: absolute;
  top: 220px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-btn-mail{
  position: absolute;
  top: 260px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.transparent-btn{
  opacity: 0.9; 
}

.service-description{
  padding: 24px;
  font-size: 16px;
  font-weight: 400;
  box-shadow:0 0 10px rgb(175 175 175 / 23%);
  /* border: #6b5e1c; */
  /* border-style: solid; */
  margin: 8px 0 42px 0;
  background-color: antiquewhite;
}

.call-me{  
  font-size: 24px;
  font-weight:600;
  font-style: italic;
  display: blo;
}

.inline{
  display: inline-block;
}

.fa-phone-270{
  transform: rotate(225deg);  
  font-size: 26px;
}
.text-justify{
  text-align: justify;
}

.text-padding-top{
  padding-top: 8px;
}

.fa-mail-2{
  font-size: 25px;
}